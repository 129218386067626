<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.raw_producement') }} # {{ procurement ?  procurement.id : '' }}</span>
      <el-button
        type="primary"
        size="small"
        class="mr-1"
        :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submitForm(true)"
      >{{ $t('message.save_and_close') }}</el-button>
      <el-button type="warning" icon="el-icon-close" size="small" @click="close()" :loading="loadingButton">{{ $t('message.close') }}</el-button>
    </header>

    <el-card class="m-3 padding_table">
      <table class="table" v-loading="loading">
        <thead>
          <tr>
            <th scope="col">{{ $t('message.name') }}</th>
            <th scope="col">{{ $t('message.qty_come') }}</th>
            <th scope="col">{{ $t('message.remainder') }}</th>
            <th scope="col">{{ $t('message.warehouse') }}</th>
            <th scope="col">{{ $t('message.day_in_warehouse') }}</th>
            <th scope="col">{{ $t('message.price2') }}</th>
            <th scope="col">{{ $t('message.sale_percentage') }}</th>
            <th scope="col">{{ $t('message.sale_price') }}</th>
            <th scope="col">{{ $t('message.date_produced') }}</th>
            <th scope="col">{{ $t('message.term') }}</th>
            <th scope="col">{{ $t('message.date_receiving') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in warehouse_products" :key="'warehouse_products-' + index">
            <td>{{ item.product ? item.product.name : '' }}</td>
            <td>{{ item.received }}</td>
            <td>{{ item.remainder }}</td>
            <td>{{ item.warehouse ? item.warehouse.name : '' }}</td>
            <td>0</td>
            <td>{{ item.origin ? item.origin.price : 0 }} {{ item.origin ? item.origin.currency ? item.origin.currency.symbol : '' : 0 }}</td>
            <td>{{ item.percent }}%</td>
            <td>{{ item.new_price  }}</td>
            <td>{{ item.manufactured_date }}</td>
            <td>{{ item.expiration_date }}</td>
            <td>{{ item.received_date }}</td>
          </tr>
        </tbody>
      </table>
    </el-card>

    <el-card class="m-3 padding_table">
      <table class="table" v-loading="loading">
        <thead>
          <tr>
            <th scope="col">{{ $t('message.name') }}</th>
            <th scope="col">{{ $t('message.quantity') }}</th>
            <th scope="col">{{ $t('message.lack_of') }}</th>
            <th scope="col">{{ $t('message.qty_come') }}</th>
            <th scope="col">{{ $t('message.measurements') }}</th>
            <th scope="col">{{ $t('message.price2') }}</th>
            <th scope="col">{{ $t('message.sale_percentage') }}</th>
            <th scope="col">{{ $t('message.sale_price') }}</th>
            <th scope="col">{{ $t('message.warehouse') }}</th>
            <th scope="col">{{ $t('message.date_produced') }}</th>
            <th scope="col">{{ $t('message.term') }}</th>
            <th scope="col">{{ $t('message.date_receiving') }}</th>
            <th scope="col">{{ $t('message.delete') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in items" :key="'items-' + index">
            <td>{{ item.source.product ? item.source.product.name : '' }}</td>
            <td>{{ item.source.quantity }}</td>
            <td>{{ item.source.received }}</td>
            <td>
              <el-input size="small" v-model="item.quantity" type="numer"></el-input>
            </td>
            <td>
                {{ item.source.measurement ? item.source.measurement.name : '' }}
            </td>
            <td>
              {{ item.source ? item.source.price : 0 }} {{item.source.currency ? item.source.currency.  symbol : '' }}
            </td>
            <td>
               <el-input size="small" v-model="item.percent"  type="number" @input="editPrice(item,$event)" :value="item.percent" ></el-input>
            </td>
            <td>
              <el-input size="small" v-model="item.new_price"  type="number" @input="editPercent(item,$event)" :value="item.new_price"></el-input>
              <!-- {{ Math.round(item.source.price * item.percent/100)+item.source.price  }} -->
            </td>
            <td>
              <warehouses v-model="item.warehouse_id" :warehouse_id="item.warehouse_id"></warehouses>
            </td>
            <td>
              <el-date-picker
                size="small"
                v-model="item.manufactured_date"
                type="date"
                style="width: 130px !important;"
                placeholder="Дата произведено"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </td>
            <td>
              <el-date-picker
                size="small"
                v-model="item.expiration_date"
                type="date"
                style="width: 130px !important;"
                placeholder="Дата оканчаны"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </td>
            <td>
              <el-date-picker
                size="small"
                v-model="item.received_date"
                type="date"
                style="width: 130px !important;"
                placeholder="Дата оканчаны"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </td>
            <td>
              <el-button size="small" type="danger" icon="el-icon-delete" circle @click="removeItem(item)"></el-button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="praduc__select">
        <el-col :span="12" class="mb-4">
            <products @append="append"></products>
        </el-col>
      </div> -->
    </el-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";
import warehouses from "@/components/inventory-select/warehouses";
import products from "@/components/selects/products";

export default {
  props: ['procurement'],
  mixins: [drawer, drawerChild],
  components:{warehouses,products},
  data() {
    return {
      loading: false,
      warehouse_products: [],
      procurement_products: [],
      items: [],
      loadingButton: false,
      drawer: {
        receive: {
          name: "receive",
          status: false
        },
      }
    };
  },
  
  methods: {
    ...mapActions({
      'loadWarehouseState': 'procurements/warehouseState',
      'receive': 'procurements/receive'
    }),
    handleChange(value) {
    },
    editPrice(item = null, value = null) {
        item.new_price = Math.round((parseFloat(item.source.price) * parseFloat(item.percent) / 100) + parseFloat(item.source.price), 2);
    },
    editPercent(item = null, value = null) {
        item.percent = Math.round(((parseFloat(item.new_price) - parseFloat(item.source.price)) * 100) / parseFloat(item.source.price), 2);
    },
    afterOpened(){
      if (this.loading === false && this.procurement) {
        this.loading = true;
        this.loadWarehouseState({id: this.procurement.id})
        .then(res => {
          const { data } = res;
          this.warehouse_products = data.warehouse_products;
          this.procurement_products = data.procurement_products;
          this.installProcurementProductsToReady();
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        })
        .finally(res => {
          this.loading = false;
        })
      }
    },
   
    submitForm(){
      let mapped_items = _.map(this.items,function(o) {
        let procurement_product = o.source;
        return {
            "procurement_item_id": procurement_product.id,
            "product_id": procurement_product.product_id,
            "warehouse_id": o.warehouse_id,
            "quantity": o.quantity,
            "manufactured_date": o.manufactured_date,
            "expiration_date": o.expiration_date,
            "received_date": o.received_date,
            "price": procurement_product.price,
            "currency_id": procurement_product.currency_id,
            "rate": procurement_product.rate,
            "percent": o.percent,
            "new_price": o.new_price
        }
      })
      this.loadingButton = true;
        this.receive({id: this.procurement.id, items : mapped_items})
        .then(res => {
          this.loadingButton = false;
          this.$alert(res);
          this.items = [];
          // this.parent().listChanged()
          // if (close == true) {
              this.close();
          // }
        })
        .catch(err => {
          this.loadingButton = false;
        })
        .finally(res => {
          this.loadingButton = false;
        })
    },
    append(product) {
        let procurement_product = _.find(this.procurement_products, ['product_id',product.id])
        if (procurement_product) {
          this.appendProduct(procurement_product)
        }
    },
    installProcurementProductsToReady(){
      this.procurement_products.forEach((item) => {
        if (item.quantity - item.received > 0) {
          this.appendProduct(item);
        }
      })
    },
    appendProduct(model, qty = 0){
        let item = {};
        item.warehouse_id = 1;
        item.quantity = qty === 0 ? model.quantity - model.received : qty;
        item.manufactured_date = new Date();
        item.expiration_date = new Date();
        item.received_date = new Date();
        item.currency_id = new Date();
        item.source = model;
        item.new_price = item.source.price;
        item.percent = 0;
        this.items.push(item);
    },
    removeItem(line) {
      if (this.items.length)
          this.items.splice(this.items.indexOf(line), 1);
    },
    afterClosed(){
      this.items = [];
    }
  }
};
</script>
<style lang="scss" >
.padding_table .table td {
  padding: 7px 3px !important;
}
</style>