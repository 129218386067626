<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center">
          <h5 class="d-inline mr-2 font-weight-bold">{{ $t('message.buy_ready_product') }}</h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              placeholder="Найти"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.start_date')"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              :placeholder="$t('message.end_date')"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >{{ $t('message.create') }}</el-button>
          <crm-column-settings :columns="columns" :modelName="'procurements'" @c-change="updateColumn"></crm-column-settings>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.date" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.supplier_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.supplier_contract_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.delivery_deadline" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.total_cost" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.status_id" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.description" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.receive" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.created_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.updated_at" :sort="sort" @c-change="updateSort"></crm-th>
            <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort"></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.date.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.date"
                :placeholder="$t('message.date')"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.supplier_id.show">
              <el-select
                filterable
                clearable
                :placeholder="$t('message.suppliers')"
                size="mini"
                v-model="filterForm.supplier_id"
              >
                <el-option
                  v-for="item in suppliers"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.supplier_contract_id.show">
              <el-select
                filterable
                clearable
                :placeholder="$t('message.suppliers_aggrement')"
                size="mini"
                v-model="filterForm.supplier_contract_id"
              >
                <el-option
                  v-for="item in supplier_contracts"
                  :key="item.number + item.id"
                  :label="item.number"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.delivery_deadline.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.delivery_deadline"
                :placeholder="$t('message.delivery_deadline')"
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.total_cost.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.total_cost"
                :placeholder="$t('message.total_cost')"
              ></el-input>
            </th>
            <th v-if="columns.status_id.show">
              <el-select
                filterable
                clearable
                :placeholder="columns.status_id.title"
                size="mini"
                v-model="filterForm.status_id"
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.title + item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </th>
            <th v-if="columns.description.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.description"
                :placeholder="$t('message.description')"
              ></el-input>
            </th>
            <th v-if="columns.receive.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.receive"
                :placeholder="$t('message.receive')"
                disabled
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.created_at"
                :placeholder="$t('message.date')"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                size="mini"
                v-model="filterForm.updated_at"
                :placeholder="$t('message.update_date')"
                type="date"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr v-for="procurement in list" :key="procurement.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ procurement.id }}</td>
            <td v-if="columns.date.show">{{ procurement.date }}</td>
            <td v-if="columns.supplier_id.show">{{ getSupplierName(procurement.supplier_id) }}</td>
            <td
              v-if="columns.supplier_contract_id.show"
            >{{ getSupplierContractName(procurement.supplier_contract_id) }}</td>
            <td
              v-if="columns.delivery_deadline.show"
            >{{ procurement.delivery_deadline  }}</td>
            <td v-if="columns.total_cost.show">{{ procurement.total_cost }}</td>
            <td v-if="columns.status_id.show">{{ getStatusName(procurement.status_id) }}</td>
            <td v-if="columns.description.show">{{ procurement.description }}</td>
            <!-- <td v-if="columns.procurements.show">
              <a href="#">Просмотр</a>
            </td>-->
            <td v-if="columns.receive.show">
              <el-button round size="mini" :type="receiveBtnType(procurement)" @click="openReceiveDrawer(procurement)">
                {{ $t('message.receive') }}  ( {{ getCoef(procurement) }} %)
              </el-button>
            </td>
            <td v-if="columns.created_at.show">{{ procurement.created_at  }}</td>
            <td v-if="columns.updated_at.show">{{ procurement.updated_at  }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="procurement"
                name="procurements"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="85%"
      :visible.sync="drawer.create.status"
      :with-header="false"
      :ref="drawer.create.name"
      :before-close="beforeClose"
    >
      <CrmCreate :drawer-name="drawer.create.name" />
    </el-drawer>
    <el-drawer
      size="85%"
      :visible.sync="drawer.update.status"
      :with-header="false"
      :ref="drawer.update.name"
      :before-close="beforeClose"
    >
      <CrmUpdate :drawer-name="drawer.update.name" />
    </el-drawer>

    <el-drawer 
      size="95%" 
      :with-header="false"
      :visible.sync="drawer.receive.status"
      :ref="drawer.receive.name"
      @opened="drawerOpened(drawer.receive.component)"
      @closed="drawerClosed(drawer.receive.component)"
      >
      <Receive 
        :procurement="activeProcurement"
        :drawer-name="drawer.receive.name"
        :ref="drawer.receive.component" />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import Receive from "./components/receive";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
  name: "buyReadyProducts",
  mixins: [list, drawer],
  data() {
    return {
      loadingData: false,
      activeProcurement: null,
      drawer: {
        create: {
          name: "create",
          status: false
        },
        update: {
          name: "update",
          status: false
        },
        receive: {
          name: "receive",
          status: false,
          component: "componentReceive"
        }
      }
    };
  },
  components: {
    CrmCreate,
    CrmUpdate,
    Receive
  },
  async mounted() {
    if (this.suppliers && this.suppliers.length === 0)
      await this.loadSuppliers();
    if (this.statuses && this.statuses.length === 0) await this.loadStatuses();
    if (this.supplier_contracts && this.supplier_contracts.length === 0)
      await this.loadSupplierContracts();
  },
  computed: {
    ...mapGetters({
      list: "procurements/list",
      columns: "procurements/columns",
      pagination: "procurements/pagination",
      filter: "procurements/filter",
      sort: "procurements/sort",
      suppliers: "suppliers/inventory",
      statuses: "statuses/inventory",
      supplier_contracts: "supplierContracts/inventory"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "procurements/index",
      updateSort: "procurements/updateSort",
      updateFilter: "procurements/updateFilter",
      updateColumn: "procurements/updateColumn",
      updatePagination: "procurements/updatePagination",
      editModel: "procurements/show",
      empty: "procurements/empty",
      delete: "procurements/destroy",
      refreshData: "procurements/refreshData",
      loadSuppliers: "suppliers/inventory",
      loadStatuses: "statuses/inventory",
      loadSupplierContracts: "supplierContracts/inventory"
    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then(res => {
            this.loadingData = false;
          })
          .catch(err => {
            // console.log(err);
            this.loadingData = false;
          });
      }
    },
    getSupplierName(id) {
      const data = this.suppliers.find(supplier => {
        return supplier.id == id;
      });
      return data ? data.name : "";
    },
    getStatusName(id) {
      const data = this.statuses.find(status => {
        return status.id == id;
      });
      return data ? data.title : "";
    },
    getSupplierContractName(id) {
      const data = this.supplier_contracts.find(sup_contract => {
        return sup_contract.id == id;
      });
      return data ? data.number : "";
    },
    beforeClose(done) {
      this.emptyModel();
      this.refreshData();
      done();
    },
    refresh() {
      this.refreshData()
        .then(res => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch(err => {});
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async res => {
          this.drawer.update.status = true;
        })
        .catch(err => {})
        .finally(() => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then(res => {
          this.$alert(res);
          this.fetchData();
        })
        .catch(err => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    drawerClosed(ref) {
      this.$refs[ref].closed();
    },
    openReceiveDrawer(model){
      this.activeProcurement = model;
      this.drawer.receive.status = true;
    },
     getCoef(model){
       if (model.items_quantity_total == 0) {
         return 0;
       }
      return _.ceil(100 * (model.items_received_quantity / model.items_quantity_total), 2)
    },
    receiveBtnType(model) {
      if (model.items_received_quantity >= model.items_quantity_total) {
        return "success";
      } else {
          return "warning";
      }
    },
  }
};
</script>
